import { AiOutlineCheckCircle } from "react-icons/ai";
import { BsDashCircleDotted } from "react-icons/bs";

import { default as ApplicantInformationIcon } from "./svg/ApplicantInformation";
import { default as CollateralIcon } from "./svg/Collateral";
import Four from "./svg/Four";
import One from "./svg/One";
import SendApplication from "./svg/SendApplication";
import Three from "./svg/Three";
import Two from "./svg/Two";

import Button from "@Components/Button";
import Grid from "@Components/Grid";
import Radio from "@Components/Radio";
import { useCreateDeal, useCreateDealAndGetPDF } from "@Hooks/useDeals";
import ApplicantInformation from "./ApplicantInformation";
import Collateral from "./Collateral";
import FinancialInfo from "./FinancialInfo";
import ReviewApplication from "./ReviewApplication";
import TradeIn from "./TradeIn";
import CreditTypeIcon from "./svg/CreditTypeIcon";

import { default as Loading } from "@Components/Loading";
import useCurrentUser from "@Hooks/useCurrentUser";
import { useGetDocuments } from "@Hooks/useDocuments";
import {
  useGetIncompleteApplication,
  useUpdateIncompleteApplicationOnClose,
} from "@Hooks/useIncompleteApplications";
import { FlatDeal, PrefilFile, TradeIn as TradeInType } from "@Types/deal";
import IncompleteDeal from "@Types/incomplete_application";
import { isSetStateFunction } from "@Utils/functions";
import { default as mixpanel } from "mixpanel-browser";
import { Fragment, SetStateAction, useState } from "react";
import { IoCheckmarkCircle, IoDocumentOutline } from "react-icons/io5";
import { useParams } from "react-router-dom";
import { queryClient } from "../../../App";
import IFrameURLModal from "../Application/Documents/PreviewDocuments/IFrameURLModal";
import ApplicationDropzone, { ParsedData } from "./ApplicationDropzone";
import { default as styles } from "./CreateApplication.module.scss";
import { default as ScanDriverLicense } from "./ScanDriverLicense";
import { default as Five } from "./svg/Five";
import { default as ScanDriverLicenseIcon } from "./svg/ScanDriverLicense";

type ActionProps = {
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  max: number;
  saved?: boolean;
  nextButtonText?: string;
};

const goToPreviousStep = (
  step: number,
  currentStep: number,
  setCurrentStep: (v: SetStateAction<number>) => void
) => {
  if (step < currentStep) setCurrentStep(step);
};
export const Actions = ({
  step,
  setStep,
  max,
  saved,
  nextButtonText = "Next",
}: ActionProps) => {
  return (
    <Grid container xs={12} className={styles.buttonContainer}>
      {step > 1 && (
        <Button
          variant="white"
          onClick={(e) => {
            e.preventDefault();
            setStep(step - 1);
          }}
        >
          Back
        </Button>
      )}

      {step === max && (
        <Fragment>
          <Button
            disabled={saved}
            id="send-create-application"
            form="create-application"
            type="submit"
          >
            Send
          </Button>
          <Button
            disabled={saved}
            style={{ width: "40%" }}
            id="send-create-application-and-download"
            form="create-application"
            type="submit"
          >
            Send and download credit application
          </Button>
        </Fragment>
      )}
      {step < max && (
        <Button
          id="next-button"
          onClick={(e) => {
            const form = document.getElementById(
              "create-application"
            ) as HTMLFormElement;

            if (form.checkValidity()) {
              e.preventDefault();
              e.stopPropagation();
              setStep(step + 1);
            }
          }}
        >
          {nextButtonText}
        </Button>
      )}
    </Grid>
  );
};

// const getInitialState = (deal: IncompleteDeal): FlatDeal => ({
//   ...deal.data.info,
//   applicant: {
//     ...(deal.data.info.applicant ?? {}),
//     birthDate: deal.data.info.applicant.birthDate
//       ? formatDateForCleave(deal.data.info.applicant.birthDate)
//       : null,
//     driverLicenseExpires: deal.data.info.applicant.driverLicenseExpires
//       ? formatDateForCleave(deal.data.info.applicant.driverLicenseExpires)
//       : null,
//     driverLicenseIssued: deal.data.info.applicant.driverLicenseIssued
//       ? formatDateForCleave(deal.data.info.applicant.driverLicenseIssued)
//       : null,
//   },
//   coApplicant: {
//     ...(deal.data.info.coApplicant ?? {}),
//     birthDate: deal.data.info.coApplicant.birthDate
//       ? formatDateForCleave(deal.data.info.coApplicant.birthDate)
//       : null,
//     driverLicenseExpires: deal.data.info.coApplicant.driverLicenseExpires
//       ? formatDateForCleave(deal.data.info.coApplicant.driverLicenseExpires)
//       : null,
//     driverLicenseIssued: deal.data.info.coApplicant.driverLicenseIssued
//       ? formatDateForCleave(deal.data.info.coApplicant.driverLicenseIssued)
//       : null,
//   },
// });
const renderDoneIcon = (currentStep: number, step: number) => {
  return currentStep > step ? (
    <div className={styles.completedIcon}>
      <AiOutlineCheckCircle size={50} />
    </div>
  ) : null;
};

function usePartialState<T, K extends keyof T>(
  mainState: T,
  mainSetState: (data: T) => void,
  key: K
): [T[K], (v: React.SetStateAction<T[K]>) => void] {
  return [
    mainState[key],
    (newValue) => {
      const newState = isSetStateFunction(newValue)
        ? newValue(mainState[key])
        : newValue;
      mainSetState({ ...mainState, [key]: newState });
    },
  ];
}

const getTradeIn = (tradeIn: TradeInType | undefined) => {
  if (
    tradeIn?.VIN ||
    tradeIn?.year ||
    tradeIn?.odometer ||
    tradeIn?.make ||
    tradeIn?.model ||
    tradeIn?.value
  ) {
    return [tradeIn];
  }
  return [];
};

const CreateApplication = () => {
  const { id } = useParams<{ id: string }>();
  const { data: deal } = useGetIncompleteApplication(id as string);
  if (deal && id) return <NewApplication deal={deal} id={id} />;
  else return <Loading />;
};
const NewApplication = ({ deal, id }: { deal: IncompleteDeal; id: string }) => {
  const user = useCurrentUser();
  const [saved, setSaved] = useState(false);

  const initialState = deal.data.info; //getInitialState(deal);

  const { data: documents } = useGetDocuments(
    `${deal._id}_${deal.updatedAt}_Applicant`,
    {
      query: {
        "data.incompleteDealId": deal._id,
        "data.info.driverLicenseData.applicantType": "Applicant",
      },
    },
    true
  );

  const mainStateSetter = (flatDeal: FlatDeal) =>
    queryClient.setQueriesData<IncompleteDeal>(
      ["incomplete_application", deal._id],
      () => ({ ...deal, data: { ...deal.data, info: flatDeal } })
    );

  const { createDeal } = useCreateDeal();
  const { createDealAndGetPDF } = useCreateDealAndGetPDF();

  useUpdateIncompleteApplicationOnClose(deal, initialState);

  const [creditType, setCreditType] = usePartialState(
    initialState,
    mainStateSetter,
    "creditType"
  );
  const [collateralType, setCollateralType] = usePartialState(
    initialState,
    mainStateSetter,
    "type"
  );
  const [applicant, setApplicant] = usePartialState(
    initialState,
    mainStateSetter,
    "applicant"
  );
  const [coApplicant, setCoApplicant] = usePartialState(
    initialState,
    mainStateSetter,
    "coApplicant"
  );
  const [collateral, setCollateral] = usePartialState(
    initialState,
    mainStateSetter,
    "vehicle"
  );
  const [tradeIn, setTradeIn] = usePartialState(
    initialState,
    mainStateSetter,
    "trade"
  );
  const [financialInfo, setFinancialInfo] = usePartialState(
    initialState,
    mainStateSetter,
    "financialInfo"
  );
  const [currentStep, setCurrentStep] = usePartialState(
    initialState,
    mainStateSetter,
    "currentStep"
  );

  const prefillFromPdf = (data: ParsedData) => {
    console.log("response", data);

    if (data?.applicant) {
      // eslint-disable-next-line
      const { original: _, ...rest } = data.applicant;
      // eslint-disable-next-line
      const { original: _co, ...restCoApplicant } = data?.coApplicant || {};
      mainStateSetter({
        ...deal.data.info,
        applicant: rest,
        creditType: data?.coApplicant ? "joint" : deal.data.info.creditType,
        coApplicant: restCoApplicant ?? deal.data.info.coApplicant,
        currentStep: 3,
      });
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const id = (event.nativeEvent as SubmitEvent)?.submitter?.id;
    event.preventDefault();
    if (currentStep === 5) {
      mixpanel.track("Deal created", {
        email: user?.data?.info?.email,
        dealershipName: user?.data?.dealership?.data?.info?.name,
      });
      const dealToSend = {
        incompleteDealId: deal._id,
        data: {
          applicant: {
            ...applicant,
            birthDate: applicant.birthDate
              ? new Date(applicant.birthDate ?? "").toISOString()
              : null,
            driverLicenseExpires: applicant.driverLicenseExpires
              ? new Date(applicant.driverLicenseExpires ?? "").toISOString()
              : null,
            driverLicenseIssued: applicant.driverLicenseIssued
              ? new Date(applicant.driverLicenseIssued ?? "").toISOString()
              : null,
            socialSecurityNumber: applicant.socialSecurityNumber.replace(
              /[- ]/g,
              ""
            ),
            annualIncome: applicant?.monthlyIncome
              ? applicant.monthlyIncome * 12
              : null,
          },
          ...(creditType === "joint"
            ? {
                coApplicant: {
                  ...coApplicant,
                  birthDate: coApplicant?.birthDate
                    ? new Date(coApplicant.birthDate ?? "").toISOString()
                    : null,
                  driverLicenseExpires: coApplicant?.driverLicenseExpires
                    ? new Date(
                        coApplicant.driverLicenseExpires ?? ""
                      ).toISOString()
                    : null,
                  driverLicenseIssued: coApplicant?.driverLicenseIssued
                    ? new Date(
                        coApplicant.driverLicenseIssued ?? ""
                      ).toISOString()
                    : null,
                  socialSecurityNumber:
                    coApplicant.socialSecurityNumber.replace(/[- ]/g, ""),
                  annualIncome: coApplicant?.monthlyIncome
                    ? coApplicant.monthlyIncome * 12
                    : null,
                },
              }
            : {}),
          info: {
            parser: initialState.parser,
            type: collateralType,
            creditType,
            vehicle: collateral,
            payment: financialInfo?.payment,
            price: {
              ...financialInfo?.price,
              trade: getTradeIn(tradeIn),
            },
            taxesAndFees: financialInfo?.taxesAndFees,
          },
        },
      };
      if (id === "send-create-application-and-download") {
        createDealAndGetPDF(dealToSend);
      } else {
        createDeal(dealToSend);
      }
      localStorage.removeItem(deal._id);
      setSaved(true);
    }
  };

  const [documentForDialog, setDocumentForDialog] = useState<
    PrefilFile | undefined
  >(undefined);

  return (
    <form id="create-application" onSubmit={handleSubmit}>
      <Grid container className={styles.mainContainer}>
        <h2 className={styles.title}>Create application</h2>
        <Grid
          xs={12}
          container
          className={styles.container}
          onClick={() => goToPreviousStep(1, currentStep, setCurrentStep)}
        >
          <Grid
            className={styles.container}
            container
            xs={currentStep === 1 ? 6 : 12}
            style={{ flexDirection: "column" }}
          >
            <Grid
              container
              xs={12}
              className={
                styles.stepTitle +
                (currentStep === 1
                  ? ` ${styles.active}`
                  : currentStep > 1
                  ? ` ${styles.completed}`
                  : ` ${styles.inactive}`)
              }
            >
              <One />
              <CreditTypeIcon />
              <p> Choose credit type </p>
              {renderDoneIcon(currentStep, 1)}
            </Grid>
            {currentStep === 1 && (
              <>
                <Grid container xs={12} className={styles.stepContainer}>
                  <Grid xs={12} style={{ marginTop: 40 }}>
                    <Radio
                      id="creditType"
                      group="creditType"
                      required
                      possibleValues={[
                        {
                          value: "individual",
                          label: "Individual",
                          description:
                            "You are relying on your income or assets.",
                        },
                        {
                          value: "joint",
                          label: "Joint",
                          description:
                            "By choosing this option, you intend to apply for 'joint credit.'",
                        },
                      ]}
                      onChange={(e) => {
                        switch (e.target.value) {
                          case "individual":
                          case "joint":
                            return setCreditType(e.target.value);

                          default:
                            return setCreditType("joint");
                        }
                      }}
                      selectedValue={creditType}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
          {currentStep === 1 && (
            <>
              <Grid container className={styles.orContainer}>
                <div>OR</div>
              </Grid>
              <Grid container className={styles.prefillContainer}>
                <Grid container style={{ flexDirection: "row" }}>
                  <div
                    style={{
                      position: "relative",
                      top: 34,
                      marginRight: 20,
                      color: "#eb8190",
                    }}
                  >
                    <ApplicantInformationIcon />
                  </div>
                  <p className={styles.prefillTitle}>
                    Prefill application with PDF
                  </p>
                </Grid>
                {currentStep === 1 && (
                  <Grid>
                    <ApplicationDropzone
                      uploadCallback={prefillFromPdf}
                      applicationId={id}
                    />
                    {documentForDialog && (
                      <IFrameURLModal
                        fileName={documentForDialog.fileName}
                        url={documentForDialog.fileUrl}
                        closeDialog={() => setDocumentForDialog(undefined)}
                      />
                    )}
                    {deal.data.info.prefillFiles && (
                      <div style={{ marginTop: 20 }}>
                        {(deal.data.info.prefillFiles ?? [])?.map(
                          (doc, index) => {
                            return (
                              <Grid
                                key={index}
                                container
                                xs={12}
                                justifyContent="space-between"
                                alignItems="center"
                                className={styles.item}
                                onClick={() => setDocumentForDialog(doc)}
                              >
                                <Grid xs={1}>
                                  <IoDocumentOutline
                                    size={30}
                                    color="#6B97F6"
                                  />
                                </Grid>
                                <Grid xs={6}>
                                  <p
                                    style={{
                                      position: "relative",
                                      top: -10,
                                    }}
                                  >
                                    <span className={styles.documentName}>
                                      {doc.fileName}
                                    </span>
                                  </p>
                                </Grid>
                                <Grid
                                  xs={5}
                                  alignContent={"center"}
                                  alignItems={"center"}
                                  style={{ textAlign: "right" }}
                                >
                                  <IoCheckmarkCircle
                                    size={20}
                                    color="green"
                                    style={{
                                      position: "relative",
                                      bottom: -5,
                                      marginRight: 10,
                                    }}
                                  />
                                  <span
                                    style={{
                                      fontSize: 14,
                                      position: "relative",
                                      marginRight: 10,
                                      color: "green",
                                    }}
                                  >
                                    Successfully parsed
                                  </span>
                                </Grid>
                              </Grid>
                            );
                          }
                        )}
                      </div>
                    )}
                  </Grid>
                )}
              </Grid>
            </>
          )}
          {currentStep === 1 && (
            <Grid
              xs={12}
              className={styles.actions}
              style={{ padding: "0 30px" }}
            >
              <Actions step={currentStep} max={4} setStep={setCurrentStep} />
            </Grid>
          )}
        </Grid>
        <Grid
          className={styles.container}
          container
          xs={12}
          onClick={() => goToPreviousStep(2, currentStep, setCurrentStep)}
        >
          <Grid
            container
            xs={12}
            className={
              styles.stepTitle +
              (currentStep === 2
                ? ` ${styles.active}`
                : currentStep > 2
                ? ` ${styles.completed}`
                : ` ${styles.inactive}`)
            }
          >
            <Two />
            <ScanDriverLicenseIcon />
            <p>Scan Driver License</p>
            {documents && currentStep > 2 && documents && documents.length ? (
              <div className={styles.completedIcon}>
                <AiOutlineCheckCircle size={50} />
              </div>
            ) : currentStep > 2 ? (
              <div className={styles.skippedIcon}>
                <BsDashCircleDotted size={50} />
              </div>
            ) : null}
          </Grid>
          {currentStep === 2 && (
            <ScanDriverLicense
              deal={deal}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
            />
          )}
        </Grid>
        <Grid
          className={styles.container}
          container
          xs={12}
          onClick={() => goToPreviousStep(3, currentStep, setCurrentStep)}
        >
          <Grid
            container
            xs={12}
            className={
              styles.stepTitle +
              (currentStep === 3
                ? ` ${styles.active}`
                : currentStep > 3
                ? ` ${styles.completed}`
                : ` ${styles.inactive}`)
            }
          >
            <Three />
            <ApplicantInformationIcon />
            <p> Applicant information</p>
            {renderDoneIcon(currentStep, 3)}
          </Grid>
          {currentStep === 3 && (
            <Grid
              container
              xs={12}
              id="applicant"
              className={styles.stepContainer}
            >
              <Grid xs={12} id="applicant">
                <ApplicantInformation
                  type="applicant"
                  title="Applicant"
                  mainApplicantState={applicant}
                  state={applicant}
                  parsedApplicant={initialState.parsedApplicant}
                  setState={setApplicant}
                />
              </Grid>
              {creditType === "joint" && (
                <Grid xs={12} id={"coApplicant"}>
                  <ApplicantInformation
                    type="coApplicant"
                    title="Co-Applicant"
                    mainApplicantState={applicant}
                    state={coApplicant}
                    parsedApplicant={initialState.parsedCoApplicant}
                    setState={setCoApplicant}
                  />
                </Grid>
              )}
              <Grid xs={12} className={styles.actions}>
                <Actions step={currentStep} max={5} setStep={setCurrentStep} />
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid
          className={styles.container}
          container
          xs={12}
          onClick={() => goToPreviousStep(4, currentStep, setCurrentStep)}
        >
          <Grid
            container
            xs={12}
            className={
              styles.stepTitle +
              (currentStep === 4
                ? ` ${styles.active}`
                : currentStep > 4
                ? ` ${styles.completed}`
                : ` ${styles.inactive}`)
            }
          >
            <Four />
            <CollateralIcon />
            <p> Collateral </p>
            {renderDoneIcon(currentStep, 4)}
          </Grid>
          {currentStep === 4 && (
            <Grid container xs={12} className={styles.stepContainer}>
              <Grid xs={12}>
                <p>Collateral you are interested in?</p>
              </Grid>
              <Grid xs={12}>
                <Collateral
                  state={collateral}
                  setState={setCollateral}
                  collateralType={collateralType}
                  setCollateralType={setCollateralType}
                />
              </Grid>
              <Grid xs={12}>
                <TradeIn state={tradeIn} setState={setTradeIn} />
              </Grid>
              <Grid xs={12}>
                <FinancialInfo
                  state={financialInfo}
                  setState={setFinancialInfo}
                />
              </Grid>
              <Grid xs={12} className={styles.actions}>
                <Actions step={currentStep} max={5} setStep={setCurrentStep} />
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid className={styles.container} container xs={12}>
          <Grid
            container
            xs={12}
            className={
              styles.stepTitle +
              (currentStep === 5 ? ` ${styles.active}` : ` ${styles.inactive}`)
            }
          >
            <Five />
            <SendApplication />
            <p> Review Application </p>
          </Grid>
          {currentStep === 5 && (
            <Grid container xs={12} className={styles.stepContainer}>
              <ReviewApplication
                applicant={applicant}
                coApplicant={coApplicant}
                collateral={collateral}
              />
              <Grid xs={12} className={styles.actions}>
                <Actions
                  step={currentStep}
                  max={5}
                  setStep={setCurrentStep}
                  saved={saved}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </form>
  );
};

export default CreateApplication;
