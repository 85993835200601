import Grid from "@Components/Grid";
import Paper from "@Components/Paper";
import useCurrentUser from "@Hooks/useCurrentUser";
import { Deal } from "@Types/deal";
import { formatDate } from "@Utils/functions";
import { hasSectionPermissions } from "@Utils/permissions";

import styles from "./FinancialInfo.module.scss";

export const calcFinanceCharge = (deal: Deal) =>
  (deal?.data?.info?.payment?.totalOfPayments || 0) -
  (deal?.data?.info?.payment?.dealTotal || 0);

const FinancialInfo = ({ deal }: { deal: Deal }) => {
  const currentUser = useCurrentUser();
  if (!hasSectionPermissions("deal", "financials", currentUser)) return null;

  const dealTotal = deal?.data?.info?.payment?.dealTotal ?? 0;
  const totalPayments = deal?.data?.info?.payment?.totalOfPayments ?? 0;
  const downPayment = deal?.data?.info?.payment?.downPayment ?? 0;
  const totalSalePrice = deal?.data?.info?.payment?.totalSalePrice ?? 0;
  const numberOfPayments = deal?.data?.info?.payment?.numberOfPayments ?? 0;
  const monthlyPayment = deal?.data?.info?.payment?.monthlyPayment ?? 0;
  const intrestRate = deal?.data?.info?.payment?.interestRate ?? 0;
  const financeCharge = calcFinanceCharge(deal);

  return (
    <Paper title="Financial Info">
      <Grid container xs={12}>
        <Grid xs={12}>
          <h2 className={styles.header}>
            Truth in lending disclosures (Taxes And Fees Included)
          </h2>
        </Grid>
        <Grid xs={2.4} className={styles.gridItem}>
          <p className={styles.title}>annual percentage rate</p>
          <p className={styles.text}>
            The cost of your credit as a yearly rate.
          </p>
          <p className={styles.digits}> {intrestRate}%</p>
        </Grid>
        <Grid xs={2.4} className={styles.gridItem}>
          <p className={styles.title}> finance charge</p>
          <p className={styles.text}>
            Тhe dollar amount the credit will cost you.
          </p>
          <p className={styles.digits}>
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(financeCharge > 0 ? financeCharge : 0)}
          </p>
        </Grid>
        <Grid xs={2.4} className={styles.gridItem}>
          <p className={styles.title}> Amount financed</p>
          <p className={styles.text}>
            Тhe amount you will have paid when you have made all schedules
            payments.
          </p>
          <p className={styles.digits}>
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(dealTotal)}
          </p>
        </Grid>
        <Grid xs={2.4} className={styles.gridItem}>
          <p className={styles.title}>Total of payments</p>
          <p className={styles.text}>
            Тhe total cost of your purchase on credit, including your down
            payment of.
          </p>
          <p className={styles.digits}>
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(totalSalePrice)}
          </p>
        </Grid>
        <Grid xs={2.4} className={styles.gridItem}>
          <p className={styles.title}> total sale price</p>
          <p className={styles.text}>
            The cost of your credit as a yearly rate.
          </p>

          <p className={styles.digits}>
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(downPayment)}
          </p>
          <p className={styles.digits}>
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(totalPayments)}
          </p>
        </Grid>

        <Grid xs={12}>
          <p className={styles.title}> Payment schedule</p>
          <p className={styles.text}>Your payment schedule will be:</p>
        </Grid>
        <Grid xs={3}>
          <p className={styles.title}> Number of payments</p>
          <p className={styles.digits}>{numberOfPayments}</p>
        </Grid>
        <Grid xs={3}>
          <p className={styles.title}> Monthly payment</p>
          <p className={styles.digits}>{monthlyPayment}</p>
        </Grid>
        <Grid xs={6}>
          <p className={styles.title}> first payment date</p>
          <p className={styles.text}>
            <b>Pmt/Month: </b>
            {deal?.data?.info?.dealDates?.contractDate
              ? [
                  formatDate(new Date(), "full"),
                  " ",
                  <span
                    style={{ color: "#eb8190" }}
                    key="restDaysToFirstPayment"
                  >
                    {`(${
                      deal?.data?.info?.payment?.daysToFirstPayment ?? 30
                    } days to first payment.)`}
                  </span>,
                ]
              : "No signed at date!"}
          </p>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default FinancialInfo;
