import { formatDateForCleave } from "@Utils/functions";
import { initialApplicantState } from "./applicants";
import { Collateral, FinancialInfo, FlatDeal, TradeIn } from "./deal";
import { Info } from "./prescreens";

type IncompleteDeal = {
  data: FormState;
  createdAt?: Date | string;
  updatedAt?: Date | string;
  deleted?: boolean;
  _id: string;
};

export interface FormState {
  info: FlatDeal;
  dealId?: string;
  userId: string;
  dealershipId: string;
}

const initialTradeInState: TradeIn = {
  VIN: "",
  year: null,
  odometer: null,
  make: "",
  model: "",
  value: null,
};

const initialFinancialInfoState: FinancialInfo = {
  price: {
    price: null,
    totalCash: null,
    payoff: null,
    payoffBank: "",
    totalTrade: null,
  },
  payment: {
    totalTaxes: null,
  },
  taxesAndFees: {
    documentRegistrationFees: null,
    titleTransfer: null,
  },
};
export const initialCollateralState: Collateral = {
  unitStatus: "",
  unitType: "",
  VIN: "",
  year: null,
  odometer: null,
  make: "",
  model: "",
  invoice: null,
  MSRP: null,
};
export const defaultState: FlatDeal = {
  type: "Automotive",
  creditType: "individual",
  applicant: initialApplicantState,
  currentStep: 1,
  vehicle: initialCollateralState,
  trade: initialTradeInState,
  coApplicant: initialApplicantState,
  financialInfo: initialFinancialInfoState,
};

export const getDefaultStateForPrescreen = (
  prescreen: Info,
  id: string
): FlatDeal => {
  return {
    creditType: "individual",
    type: "Automotive",
    applicant: {
      ...initialApplicantState,
      ...prescreen?.applicant,
      driverLicenseExpires: formatDateForCleave(
        prescreen.applicant?.driverLicenseExpires
      ),
      driverLicenseIssued: formatDateForCleave(
        prescreen?.applicant?.driverLicenseIssued
      ),
      birthDate: formatDateForCleave(prescreen?.applicant?.birthDate),
    },
    currentStep: 3,
    vehicle: { ...initialCollateralState, ...prescreen?.vehicle },
    trade: initialTradeInState,
    coApplicant: initialApplicantState,
    financialInfo: {
      ...initialFinancialInfoState,
      payment: {
        ...initialFinancialInfoState.payment,
        dealTotal: prescreen?.payment?.dealTotal || undefined,
      },
    },
    // creditPrequalify:prescreen.data.creditPrequalify,
    prediction: prescreen.prediction,
    payment: prescreen.payment,
    prescreenId: id,
  };
};

export default IncompleteDeal;
