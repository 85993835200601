import ApplicantFilter from "@Components/Filters/ApplicantFilter";
import DateFilter from "@Components/Filters/DateFilter";
import LenderFilter from "@Components/Filters/LenderFilter";
import SelectFilter from "@Components/Filters/SelectFilter";
import TextFilter from "@Components/Filters/TextFilter";
import IconButton from "@Components/IconButton";
import Table from "@Components/Table";
import { Column } from "@Components/Table/types";

import {
  DateFilterPreviewProps,
  FilterPreviewProps,
} from "@Components/Filters/types";
import { Deal, DealStatus } from "@Types/deal";

import useGetDeals from "@Hooks/useDeals";

import { formatDate } from "@Utils/functions";

import {
  formatCollateral,
  formatCustomersNames,
} from "@Pages/Applications/Applications";

import { AiOutlineArrowRight } from "react-icons/ai";
import { HiBuildingLibrary, HiOutlineBuildingLibrary } from "react-icons/hi2";
import { IoCalendarClearOutline } from "react-icons/io5";
import { MdOutlinePersonOutline, MdPerson } from "react-icons/md";
import { RiCarFill, RiCarLine } from "react-icons/ri";

import styles from "./RecentApplications.module.scss";

import ExpandableComponent from "@Components/ExpandableContainer/ExpandableContainer";
import ManagerFilter from "@Components/Filters/ManagerFilter";
import { bbStatusToDStatus } from "@Components/Stepper/Stepper";
import StepperPreview from "@Components/Stepper/StepperPreview";
import Tooltip from "@Components/Tooltip";
import useCurrentUser from "@Hooks/useCurrentUser";
import useGetIncompleteApplications from "@Hooks/useIncompleteApplications";
import TitleIssuesPreview from "@Pages/TitleIssues/TitleIssuesPreview";
import { hasActionPermissions } from "@Utils/permissions";
import { BsCalendarFill } from "react-icons/bs";
import { IoIosWarning } from "react-icons/io";
import { MdError } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import UnfinishedApplications from "./UnfinishedApplications";
import Limits from "@Pages/TitleIssues/Limits";

export const statusToColor = (status: DealStatus) => {
  switch (status) {
    case "funded":
      return "#17DB71";
    case "lead":
      return "#C7C6CB";
    case "cancelled":
    case "denied":
    case "dead":
      return "#EC2F49";
    default:
      return "#FFBB6C";
  }
};
const RecentApplication = () => {
  const navigate = useNavigate();
  const { data: incompleteApplications } = useGetIncompleteApplications();
  const user = useCurrentUser();

  const titleManagement = user?.data.dealership?.data.info.titleManagement;
  const isRestricted = titleManagement?.isRestricted;

  const fundingSuspended =
    (titleManagement?.outstandingDeals || 0) >
    (titleManagement?.outstandingTitlesFundingLimit || 0);
  const applicationSuspended =
    (titleManagement?.outstandingDeals || 0) >
    (titleManagement?.outstandingTitlesApplicationsLimit || 0);

  const columns: Column<Deal>[] = [
    {
      id: "status",
      label: "Status",
      value: (deal) => (
        <div style={{ position: "relative" }}>
          <Tooltip
            content={<StepperPreview deal={deal} />}
            tooltipStyle={{
              width: "1200px",
              paddingBottom: "20px",
              height: "220px",
            }}
          >
            {bbStatusToDStatus(deal?.data?.info?.status)?.title || "N/A"}
          </Tooltip>
        </div>
      ),
      filters: [
        {
          placeholder: "Status",
          preview: SelectFilter,
          queryPath: ["data", "info", "status"],
          valuesForSelect: Object.values(DealStatus).map((x) => ({
            label: x,
            value: x,
          })),
        },
      ],
      style: (entry) => ({
        fontWeight: 700,
        fontSize: 18,
        background: statusToColor(entry?.data?.info?.status),
        padding: "5px 0px",
        borderRadius: "5px",
        justifyContent: "center",
        width: "100%",
        color: "#FFF",
        maxWidth: "250px",
        whiteSpace: "nowrap",
        textAlign: "center",
      }),
    },
    {
      id: "applicants",
      label: "Applicants",
      value: (deal) => formatCustomersNames(deal),
      filters: [
        {
          placeholder: "Applicants",
          preview: ApplicantFilter,
          queryPath: [],
        },
      ],
      style: () => ({
        color: "#5e5d65",
      }),
      icon: <MdOutlinePersonOutline color="#9e9ca7" size={29} />,
      hoverIcon: <MdPerson color="#5e5d65" size={29} />,
      truncate: 15,
    },
    {
      id: "collateral",
      label: "Collateral",
      value: (deal) => formatCollateral(deal),
      filters: [
        {
          placeholder: "Collateral",
          preview: TextFilter,
          queryPath: ["data", "info", "vehicle", "make"],
          partialSearch: true,
          caseInsensitive: true,
        },
      ],
      style: () => ({
        color: "#5e5d65",
      }),
      icon: <RiCarLine color="#9e9ca7" size={29} />,
      hoverIcon: <RiCarFill color="#5e5d65" size={29} />,
      truncate: 15,
    },
    {
      id: "lender",
      label: "Lender",
      value: (deal) => deal?.data?.lender?.data?.info?.name ?? "N/A",
      filters: [
        {
          placeholder: "Lender",
          preview: LenderFilter,
          queryPath: ["data", "lenderId"],
        },
      ],
      style: () => ({
        color: "#5e5d65",
      }),
      icon: <HiOutlineBuildingLibrary color="#9e9ca7" size={29} />,
      hoverIcon: <HiBuildingLibrary color="#5e5d65" size={29} />,
      truncate: 15,
    },
    {
      id: "manager",
      label: "Manager",
      value: (deal) =>
        deal?.data?.user?.data?.info?.firstName
          ? `${deal?.data?.user?.data?.info?.firstName ?? ""} ${
              deal?.data?.user?.data?.info?.lastName ?? ""
            } `
          : "N/A",
      filters: [
        {
          placeholder: "Manager",
          preview: ManagerFilter,
          queryPath: ["data", "userId"],
        },
      ],
      style: () => ({
        color: "#5e5d65",
      }),
      icon: <MdOutlinePersonOutline color="#9e9ca7" size={29} />,
      hoverIcon: <MdPerson color="#5e5d65" size={29} />,
    },
    {
      id: "createdAt",
      label: " Created at",
      value: (deal) => formatDate(deal?.createdAt),
      filters: [
        {
          placeholder: "Date",
          preview: (props: FilterPreviewProps) => (
            <DateFilter {...(props as unknown as DateFilterPreviewProps)} />
          ),
          queryPath: ["createdAt"],
        },
      ],
      style: () => ({
        color: "#5e5d65",
      }),
      icon: <IoCalendarClearOutline color="#9e9ca7" size={29} />,
      hoverIcon: <BsCalendarFill color="#5e5d65" size={29} />,
    },

    {
      id: "actions",
      label: "Actions",
      value: (deal) => (
        <IconButton
          id={`open-recent-deal-${deal?._id}`}
          className={"blueCircle"}
          onClick={() => {
            navigate(`/applications/${deal._id}`);
          }}
        >
          <AiOutlineArrowRight size={18} />
        </IconButton>
      ),
    },
  ];

  return (
    <div className={styles.recentApplicantonContainer}>
      {isRestricted && (fundingSuspended || applicationSuspended) ? (
        <div className={styles.warning}>
          <MdError color="#ec2f49" size={60} />
          <h3>
            {`
            The limit for deals without title documents has been surpassed.  Please click here for more details and take the necessary steps to resolve the issue.
            `}
            <span
              style={{ color: "#6b97f6", cursor: "pointer", fontWeight: 500 }}
              onClick={() => navigate("/title_info")}
            >
              Click here for details.
            </span>
          </h3>
        </div>
      ) : null}
      <Limits />

      <div className={styles.warning}>
        <IoIosWarning color="#ffbb6c" size={200} />
        <h3>
          {`Important Update: Driver's License Submission for Application Process
          We want to inform you of an important update regarding the submission
          of driver's licenses for credit applications. Effective immediately,
          we will now require pictures of the driver's license (front and back)
          instead of scanned copies. This change is being implemented in
          response to increasing fraud concerns, which have become a growing
          challenge in the industry. By requiring clear, high-quality images, we
          aim to enhance security and protect both dealers and lenders from
          potentially fraudulent activities. Additionally, this new process will
          address one of the most common causes of funding delays—unreadable or
          unclear license scans—helping to streamline approvals and minimize
          “funding held” issues. To make this transition as seamless as
          possible, we encourage you to use our mobile application (available on
          iOS and Android) to take and upload pictures directly. Alternatively,
          you may take pictures using your devices and upload them through our
          existing platform.`}
        </h3>
      </div>

      {hasActionPermissions("title_issue", "read", user) && (
        <>
          <ExpandableComponent title="Upload title documents for listed loans">
            <TitleIssuesPreview />
          </ExpandableComponent>
        </>
      )}

      {hasActionPermissions("incomplete_application", "read", user) &&
      Array.isArray(incompleteApplications) &&
      incompleteApplications?.length ? (
        <UnfinishedApplications />
      ) : null}

      <h2 id="test1" className={styles.title}>
        Recent Applications
      </h2>
      <Table<Deal>
        columns={columns}
        useGetFunction={useGetDeals}
        entityType="applications"
      />
    </div>
  );
};

export default RecentApplication;
