import Cleave from "cleave.js/react";
import { ChangeEvent, useRef } from "react";
import styles from "../TextField/input.module.scss";

interface DateFieldProps {
  id?: string;
  placeholder?: string;
  required?: boolean;
  title: string;
  value: string | number | null | undefined;
  name?: string;
  style?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  autofilled?: boolean;
  max?: string;
  min?: string;
  customErrorMessage?: string;
}

export const dateToHtml = (date: string | Date | null | undefined): string => {
  if (!date) return "";
  const parsedDate = typeof date === "string" ? new Date(date) : date;
  if (isNaN(parsedDate.getTime())) return "";
  return `${parsedDate.getFullYear()}-${(parsedDate.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${parsedDate.getDate().toString().padStart(2, "0")}`;
};

const formatDateForCleave = (
  date: string | Date | null | undefined
): string => {
  if (!date) return "";
  const parsedDate = typeof date === "string" ? new Date(date) : date;
  if (isNaN(parsedDate.getTime())) return "";
  return `${(parsedDate.getMonth() + 1)
    .toString()
    .padStart(2, "0")}/${parsedDate
    .getDate()
    .toString()
    .padStart(2, "0")}/${parsedDate.getFullYear()}`;
};

const isISODate = (dateStr: string): boolean => {
  return /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/.test(dateStr);
};

const DateField = ({
  id,
  placeholder,
  title,
  onChange,
  name,
  value,
  style,
  labelStyle,
  required = false,
  autofilled = false,
  max = "",
  min = "",
  customErrorMessage = "",
}: DateFieldProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const formattedCleaveValue =
    typeof value === "string" && isISODate(value)
      ? formatDateForCleave(value)
      : value ?? "";

  return (
    <div
      className={`${styles.inputContainer} ${autofilled && styles.autofilled}`}
    >
      <label className={styles.label} htmlFor={name} style={labelStyle}>
        {`${title}${required ? " *" : ""}`}
        {autofilled ? (
          <span className={styles.autofilledLabel}> (Autofilled)</span>
        ) : null}
      </label>
      <Cleave
        options={{
          date: true,
          datePattern: ["m", "d", "Y"],
        }}
        style={style}
        pattern=".{10,10}"
        min={10}
        id={id}
        placeholder={placeholder}
        value={formattedCleaveValue}
        onChange={(e) => {
          if (customErrorMessage) {
            inputRef?.current?.setCustomValidity("");
          }
          if (onChange) onChange(e);
        }}
        name={name}
        required={required}
        className={styles.input}
      />
      {max || min ? (
        <input
          ref={inputRef}
          style={{ width: "100%", height: "0px", opacity: 0 }}
          type="date"
          id="start"
          value={dateToHtml(value as string | Date)}
          required={required}
          name={name + "-hidden-validation"}
          max={max}
          min={min}
          onInvalid={
            customErrorMessage
              ? (e) =>
                  (e as ChangeEvent<HTMLInputElement>).target.setCustomValidity(
                    customErrorMessage
                  )
              : undefined
          }
          onInput={
            customErrorMessage
              ? (e) =>
                  (e as ChangeEvent<HTMLInputElement>).target.setCustomValidity(
                    ""
                  )
              : undefined
          }
        />
      ) : null}
    </div>
  );
};

export default DateField;
