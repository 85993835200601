import Button from "@Components/Button";
import Dialog from "@Components/Dialog";
import { DialogActions } from "@Components/Dialog/DialogActions";
import { DialogContent } from "@Components/Dialog/DialogContent";
import useCurrentUser from "@Hooks/useCurrentUser";
import { useStickyState } from "@Hooks/useStickyState";
import { useState } from "react";
import { MdOutlineNewReleases } from "react-icons/md";
import styles from "./WhatsNew.module.scss";

const WhatsNew = () => {
  const user = useCurrentUser();
  if (user && user.data.info.userType !== "owner") {
    return <></>;
  }

  const [isSeenRelease, setIsSeenRelease] = useStickyState(
    false,
    // change this name when releasing something new.
    "release-01/08/2025"
  );

  const [show, setShow] = useState(false);
  return (
    <>
      <button
        className={styles.button}
        onClick={() => {
          setShow(!show);
        }}
      >
        <span>
          <MdOutlineNewReleases />
        </span>

        {!isSeenRelease ? (
          <div style={{ paddingLeft: 10 }}>Click here to see whats new </div>
        ) : (
          ""
        )}
      </button>
      {show ? (
        <Dialog
          titleColor="blue"
          containerStyle={{
            top: "30px",
            transform: "unset",
            left: 0,
          }}
          id="create-change-request"
          open={show}
          title="Whats new?"
          closeIcon
          size="lg"
          closeFn={() => {
            setIsSeenRelease(true);
            setShow(false);
          }}
        >
          <DialogContent>
            <div>
              <h2>
                Release Announcement 01/08/2025: Minor Bug Fixes and
                Enhancements!
              </h2>
              <p>
                We’re rolling out a new update with important bug fixes and
                usability improvements to enhance your experience with Web
                Finance Direct. These updates focus on improving system
                performance and addressing user-reported issues.
              </p>

              <h3>Key Enhancements:</h3>
              <ul>
                <li>
                  <strong>Additional Monthly Income Field Enhancement:</strong>
                  <ul>
                    <li>
                      The &quot;Additional Monthly Income&quot; field in the
                      &quot;Create Application&quot; form has been upgraded to a
                      list model. Users can now add{" "}
                      <strong>multiple entries</strong> for additional monthly
                      incomes, including specifying the source of each income.
                    </li>
                    <li>
                      This allows for more detailed and flexible income
                      reporting for applicants.
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>
                    Updated Application Review Page – Credit Application
                    Download Access
                  </strong>
                  <ul>
                    <li>
                      The &quot;Print Application&quot; functionality has been
                      moved for improved accessibility.
                    </li>
                    <li>
                      Users can now easily access the{" "}
                      <strong>Print Credit Application</strong> option by
                      clicking the <strong>Print Icon</strong> located next to
                      the <strong>Application title</strong> at the top of the
                      application review page. This provides a more streamlined
                      experience for printing the application directly from the
                      review page.
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>
                    New Button for Sending and Downloading Credit Application
                  </strong>
                  <ul>
                    <li>
                      A new button titled{" "}
                      <strong>
                        &quot;Send and Download Credit Application&quot;
                      </strong>{" "}
                      has been added to the application submission process.
                    </li>
                    <li>
                      Upon clicking this button, the application will be
                      submitted and automatically downloaded as a PDF file,
                      providing a seamless user experience for sending and
                      storing the application at the same time.
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>
                    Issue Fix – Missing Fields in Credit Application PDF
                  </strong>
                  <ul>
                    <li>
                      An issue where some fields in the credit application PDF
                      were not being populated correctly has been resolved.
                    </li>
                    <li>
                      All relevant fields are now properly populated, ensuring
                      that the generated PDF accurately reflects the submitted
                      application data.
                    </li>
                  </ul>
                </li>
              </ul>
              <p>
                We’re committed to continually improving Web Finance Direct to
                meet your needs. Stay tuned for more updates!
              </p>

              <p>Best regards,</p>
              <p>The Web Finance Direct Team</p>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              variant="blue"
              onClick={() => {
                setShow(false);
                setIsSeenRelease(true);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  );
};
export default WhatsNew;
