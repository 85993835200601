import {
  getIncompleteApplications,
  getIncompleteApplication,
  createIncompleteApplication,
  deleteIncompleteApplication,
  updateIncompleteApplication,
} from "@Http/incomplete_applications";
import { useQuery, useMutation } from "@tanstack/react-query";
import { FlatDeal } from "@Types/deal";
import { HttpQuery } from "@Types/http";
import IncompleteDeal from "@Types/incomplete_application";
import { MutationError } from "@Types/index";
import { hasActionPermissions } from "@Utils/permissions";
import { useCallback, useEffect, useRef } from "react";
import { useBeforeUnload, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { queryClient } from "../App";
import useCurrentUser from "./useCurrentUser";

export const useGetIncompleteApplications = (query?: HttpQuery) => {
  const user = useCurrentUser();
  const queryKey = ["incomplete_applications", query];

  const permissions = hasActionPermissions(
    "incomplete_application",
    "read",
    user
  );
  const { data, isLoading, error } = useQuery<
    IncompleteDeal[],
    MutationError,
    IncompleteDeal[]
  >({
    queryKey,
    queryFn: getIncompleteApplications(query),
    enabled: permissions,
  });

  return {
    data,
    isLoading,
    error,
  };
};

export const useGetIncompleteApplication = (id: string, enabled = true) => {
  const queryKey = ["incomplete_application", id];

  const { data, isLoading, error } = useQuery<
    IncompleteDeal,
    MutationError,
    IncompleteDeal
  >({
    queryKey,
    queryFn: getIncompleteApplication(id),
    enabled,
  });
  return {
    data,
    isLoading,
    error,
  };
};

export const useCreateIncompleteApplication = () => {
  const navigate = useNavigate();

  const mutation = useMutation<IncompleteDeal, MutationError, FlatDeal>(
    createIncompleteApplication(),
    {
      onSuccess: (incomplete_application) => {
        navigate(`/incomplete_applications/${incomplete_application._id}`, {
          replace: true,
        });

        queryClient.setQueriesData<IncompleteDeal[]>(
          ["incomplete_applications"],
          (oldData) => [incomplete_application, ...(oldData ?? [])]
        );
      },
    }
  );
  return {
    createDeal: mutation.mutate,
    loading: mutation.isLoading,
    success: mutation.isSuccess,
    error: mutation.error,
  };
};

export const useDeleteIncompleteApplication = () => {
  const mutation = useMutation<IncompleteDeal, MutationError, IncompleteDeal>(
    deleteIncompleteApplication(),
    {
      onSuccess: (incomplete_application) => {
        toast.success("Successfully deleted an incomplete application.", {
          position: "top-center",
        });

        queryClient.setQueriesData<IncompleteDeal[]>(
          ["incomplete_applications"],
          (oldData) =>
            oldData?.filter((x) => x._id !== incomplete_application._id)
        );
      },
    }
  );
  return {
    deleteIncompleteDeal: mutation.mutate,
    loading: mutation.isLoading,
    success: mutation.isSuccess,
    error: mutation.error,
  };
};

export const useUpdateIncompleteApplication = () => {
  const mutation = useMutation<IncompleteDeal, MutationError, IncompleteDeal>(
    updateIncompleteApplication(),
    {
      onSuccess: (incomplete_application) => {
        queryClient.setQueryData<IncompleteDeal>(
          ["incomplete_application", incomplete_application._id],
          () => incomplete_application
        );
        queryClient.setQueriesData<IncompleteDeal[]>(
          ["incomplete_applications"],
          (oldData) =>
            oldData?.map((x) =>
              x._id === incomplete_application._id ? incomplete_application : x
            )
        );
      },
    }
  );
  return {
    updateIncompleteApplication: mutation.mutate,
    loading: mutation.isLoading,
    success: mutation.isSuccess,
    error: mutation.error,
  };
};

export const useUpdateIncompleteApplicationOnClose = (
  incompleteDeal: IncompleteDeal,
  data: FlatDeal
) => {
  const dealRef = useRef(data);

  const { updateIncompleteApplication } = useUpdateIncompleteApplication();

  const updateDeal = () => {
    if (incompleteDeal && data.currentStep !== 5)
      updateIncompleteApplication({
        ...incompleteDeal,
        data: { ...incompleteDeal.data, info: dealRef.current },
      });
  };
  const updateDealCallback = useCallback(() => updateDeal(), [dealRef.current]);

  useBeforeUnload(() => {
    updateDealCallback();
  });

  useEffect(() => {
    return () => {
      updateDeal();
    };
  }, []);

  useEffect(() => {
    dealRef.current = data;
  }, [data]);
};

export default useGetIncompleteApplications;
