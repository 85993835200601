import { Applicant, ApplicantInfo } from "./applicants";
import { CreditPrequalify } from "./credit";
import { CreditBureau } from "./credit_bureaus";
import { DealerNote } from "./dealer_notes";
import { Note } from "./note";
import { Prediction } from "./prescreens";
import { PrintedDocument } from "./required_documents";
import { Stipulation } from "./stipulations";
import { User } from "./users";

export interface Deal {
  _id: string;
  createdAt: string | Date;
  updatedAt: string | Date;
  data: {
    titleIssueId?: string;
    notes: Note[];
    dealerNotes?: DealerNote[];
    stipulations?: Stipulation[];
    info: {
      extractedCreditScores?: {
        applicant: {
          score: number;
        };
        coApplicant: {
          score: number;
        };
      };
      potentialEndDate: string;
      statusReasons?: StatusReasons;
      fundingPaymentMethod: "ACH" | "Check";
      statusHistory?: StatusHistory[];
      type: CollateralTypes;
      refNumber: string;
      status: DealStatus;
      vehicle: Collateral;
      payment: Payment;
      dealDates?: DealDates;
      taxesAndFees: TaxesAndFees;
      price: {
        trade: TradeIn[] | undefined;
        price: number | undefined;
        totalCash: number | undefined;
        totalTrade: number | undefined;
        payoff: number | undefined;
        payoffBank: string | undefined;
      };
      profit: {
        dealershipProfit: {
          totalProfit: number;
        };
      };
    };
    user: User;
    lenderId: string;
    lender: { _id: string; data: { info: { name: string } } };
    applicant: Applicant;
    coApplicant: Applicant;
    creditBureaus?: CreditBureau[];
    printedDocuments: PrintedDocument[];
    encodedDealId: string;
    payoffBank: { _id: string; data: { info: { name: string } } };
  };
}

export type CreditType = "joint" | "individual";
export type PrefilFile = {
  fileUrl: string;
  fileName: string;
  metadata: { name: string; contentType: string };
};
export type FlatDeal = {
  parser?: { applicationParserType: string };
  currentStep: number;
  creditType: CreditType;
  type: CollateralTypes;
  applicant: ApplicantInfo;
  coApplicant: ApplicantInfo;
  parsedApplicant?: ApplicantInfo;
  parsedCoApplicant?: ApplicantInfo;
  vehicle: Collateral;
  financialInfo: FinancialInfo;
  trade?: TradeIn;
  prefillFiles?: PrefilFile[];
  creditPrequalify?: CreditPrequalify[];
  prediction?: Prediction;
  payment?: {
    dealTotal?: number;
  };
  prescreenId?: string;
};
export enum ReasonsDidNotProcess {
  // OutOfLendingArea = "Out of lending area",
  InsufficientCollateral = "Insufficient collateral",
  InsufficientCredit = "Insufficient credit/No subprime",
}
export enum ReasonsDead {
  NeedCosigner = "Need cosigner",
  Fraudulent = "Fraudulent",
  WentWithDifferentLender = "Went with different lender",
  CannotAffordPayment = "Cannot afford payment",
  VehicleDidNotMeetExpectations = "Vehicle did not meet expectations",
  CannotComeUpWithAdditionalCashDown = "Cannot come up with additional cash down",
  NoLongerInterestedInPurchasing = "No longer interested in purchasing",
}
export type StatusReason = {
  status:
    | DealStatus.DidNotProcess
    | DealStatus.Dead
    | DealStatus.Approved
    | DealStatus.Countered
    | DealStatus.SentToDealer;
  date: string;
  marked: boolean;
  reason: ReasonsDidNotProcess | ReasonsDead;
  userId: string;
};

export type StatusReasonApplicant = {
  status:
    | DealStatus.DidNotProcess
    | DealStatus.Dead
    | DealStatus.Approved
    | DealStatus.Countered
    | DealStatus.SentToDealer;
  date: string;
  reason: string;
  marked: boolean;
};
export type StatusReasons = {
  manager: StatusReason;
  dealership: StatusReason;
  applicant: StatusReasonApplicant;
};
export type LocalStorageDeal = [string, FlatDeal];
export enum DealStatus {
  Lead = "lead",
  CreditCheck = "credit check",
  WaitingForDealDetails = "waiting for deal details",
  DealCreated = "deal created",
  PendingLenderDecision = "pending lender decision",
  Approved = "approved",
  Countered = "countered",
  SentToDealer = "sent to dealer",
  SubmittedForFunding = "submitted for funding",
  FundingHeld = "funding held",
  DidNotProcess = "did not process",
  Funded = "funded",
  Cancelled = "cancelled",
  Denied = "denied",
  Dead = "dead",
}
export interface StatusHistory {
  status: DealStatus;
  userEmail: string;
  date: string;
}
export const collateralTypes = [
  "Automotive",
  "Marine",
  "Recreational Vehicles",
  "Power Sport",
] as const;

export type CollateralTypes = (typeof collateralTypes)[number];
export interface FinancialInfo {
  payment: {
    totalTaxes: number | null;
    dealTotal?: number;
  };
  taxesAndFees: Pick<
    TaxesAndFees,
    "documentRegistrationFees" | "titleTransfer"
  >;
  price: Pick<Price, "price" | "totalCash" | "payoff" | "totalTrade"> & {
    payoffBank: string;
  };
}

export interface TaxesAndFees {
  isFixedTax: boolean;
  titleTransfer: number | null;
  license: number;
  documentRegistrationFees: number | null;
  filingFees: number;
  otherFees: number;
  // fundingFees?: FundingFee[];
  totalMembershipFees: number;
  totalUCCFees: number;
  wfdMinimumFee: number;
  acquisitionFee: number;
  lenderAcquisitionFee: number;
  detailed: {
    registrationTax: number;
    plateFee: number;
    tempPlateFee: number;
    wheelageTax: number;
    psVehicleFee: number;
    transferTax: number;
    titleTransferFee: number;
    techSurchargeFee: number;
    lienFee: number;
    deputyFilingFee: number;
    exciseTax: number;
    licenseAndRegFee: number;
    processingFee: number;
    contributionFee: number;
  };
}
export interface Payment {
  type: "monthly" | "yearly";
  numberOfPayments: number;
  daysToFirstPayment: number;
  interestRate: number;
  dealTotal: number;
  downPayment: number;
  amountFinanced: number;
  totalAftermarket: number;
  totalTaxes: number | null;
  localTaxes: number;
  stateTaxes: number;
  taxableAmount: number;
  totalFees: number;
  monthlyPayment: number;
  totalOfPayments: number;
  totalSalePrice: number;
  fundedAmount: number;
  buyPercent: number;
  dealershipSplit?: number;
  wfdSplit?: number;
  dealershipGAPSplit?: number;
  wfdGAPSplit?: number;
  dealershipWarrantySplit?: number;
  wfdWarrantySplit?: number;
  // splitMethod: RelationTypes;

  correspondentPercent?: number;
  reserve?: number;
  // reserveMethod?: ReserveMethod;
  maxReserve?: number;
  markupData?: {
    markup: number;
    reservePercentage: number;
    isDefault?: boolean;
    dealerSplitPercentage?: number;
    extraReserveFixed?: number;
    extraReservePercentage?: number;
  };
}
export interface DealDates {
  contractDate?: string;
  sentToLender?: string;
  fundedAt?: string;
  payrollDate?: string;
  firstPaymentDate: string;
}
export interface Price {
  price: number | null;
  options: number | null;
  rebates: number | null;
  totalCash: number | null;
  trade?: TradeIn[] | undefined;
  totalTrade: number | null;
  payoff: number | null;
  netTrade: number;
}
export interface Collateral {
  unitStatus?: string;
  unitType?: string;
  odometer: number | null;
  VIN: string;
  invoice?: number | null;
  MSRP?: number | null;
  year: number | null;
  make: string;
  model: string;
}

export const initialCollateralState: Collateral = {
  unitStatus: "",
  unitType: "",
  VIN: "",
  year: null,
  odometer: null,
  make: "",
  model: "",
  invoice: null,
  MSRP: null,
};

export interface TradeIn {
  year?: number | null;
  model?: string;
  odometer?: number | null;
  make?: string;
  VIN?: string;
  value?: number | null;
}
export const initialTradeInState: TradeIn = {
  VIN: "",
  year: null,
  odometer: null,
  make: "",
  model: "",
  value: null,
};

export const initialFinancialInfoState: FinancialInfo = {
  price: {
    price: null,
    totalCash: null,
    payoff: null,
    payoffBank: "",
    totalTrade: null,
  },
  payment: {
    totalTaxes: null,
  },
  taxesAndFees: {
    documentRegistrationFees: null,
    titleTransfer: null,
  },
};

export interface CreateDealRequest {
  incompleteDealId: string;
  data: {
    info: {
      parser?: {
        applicationParserType: string;
      };
      type: CollateralTypes;
      creditType: CreditType;
      taxesAndFees?: {
        documentRegistrationFees: number | null;
        titleTransfer: number | null;
      };
      vehicle?: Collateral;
      price?: {
        price?: null | number;
        totalCash?: null | number;
        payoff?: null | number;
        payoffBank?: string;
        totalTrade?: null | number;
        trade: TradeIn[] | undefined;
      };
      payment?: {
        totalTaxes: number | null;
      };
    };
    applicant: ApplicantInfo;
    coApplicant?: ApplicantInfo;
  };
}

export type CreatCollateralChangeRequest = {
  data: {
    dealId: string;
    collateral: Collateral;
    financialInfo: FinancialInfo;
    collateralType: CollateralTypes;
  };
};
export const dealIsPastSubmittedForFunding = (status: DealStatus) =>
  !!(
    status === DealStatus.SubmittedForFunding ||
    status === DealStatus.FundingHeld ||
    status === DealStatus.Funded
  );
