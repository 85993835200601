import Grid from "@Components/Grid";
import { Info } from "@Types/prescreens";
import { renderFieldData } from "@Pages/Applications/CreateApplication/ReviewApplication/ReviewApplication";
import { IoMdInformationCircle } from "react-icons/io";

import styles from "./Prescreens.module.scss";
import {
  formatDate,
  formatNumberAsCurrency,
  isValidDate,
} from "@Utils/functions";
import Button from "@Components/Button";
import useGetIncompleteApplications, {
  useCreateIncompleteApplication,
} from "@Hooks/useIncompleteApplications";
import { HttpQuery } from "@Types/http";
import { getDefaultStateForPrescreen } from "@Types/incomplete_application";
import { useNavigate } from "react-router-dom";

type Props = {
  prescreen: Info;
  id: string;
};
const PrescreenView = ({ prescreen, id }: Props) => {
  const { createDeal } = useCreateIncompleteApplication();
  const navigate = useNavigate();
  const { data: deal } = useGetIncompleteApplications({
    query: { "data.prescreenId": id },
  } as HttpQuery);

  const incompleteDeal = deal?.[0];

  const handleCreateApp = () => {
    incompleteDeal
      ? navigate(`/incomplete_applications/${incompleteDeal._id}`, {
          replace: true,
        })
      : createDeal(getDefaultStateForPrescreen(prescreen, id));
  };
  const renderPrescreen = () => {
    return (
      <>
        <Grid xs={6}>
          <div className={styles.container}>
            <div className={styles.titleSecondary}>Personal Info</div>
            {renderFieldData(
              "Name",
              `${prescreen.applicant.firstName ?? ""} ${
                prescreen.applicant.lastName ?? ""
              }`
            )}

            {renderFieldData(
              "Date of Birth",
              `${formatDate(prescreen.applicant?.birthDate) ?? "-"}`
            )}
            {renderFieldData(
              "Number",
              prescreen.applicant.currentAddressNumber ?? "-"
            )}
            {renderFieldData(
              "Address",
              prescreen.applicant.currentAddress ?? "-"
            )}
            {renderFieldData(
              "Zip Code",
              prescreen.applicant.currentZipCode ?? "-"
            )}
            {renderFieldData("State", prescreen.applicant.currentState ?? "-")}
            {renderFieldData("City", prescreen.applicant.currentCity ?? "-")}
          </div>
        </Grid>
        <Grid xs={6}>
          <div className={styles.container}>
            <div className={styles.titleSecondary}>Drivers License</div>
            {renderFieldData(
              "License Number",
              `${prescreen.applicant.driverLicenseNumber ?? "-"}`
            )}
            {renderFieldData(
              "State",
              `${prescreen.applicant.drivingLicenseState ?? "-"}`
            )}
            {renderFieldData(
              "License Issue Date",
              `${
                isValidDate(prescreen.applicant?.driverLicenseIssued) &&
                prescreen.applicant?.driverLicenseIssued
                  ? formatDate(
                      new Date(prescreen.applicant.driverLicenseIssued)
                    )
                  : "-"
              }`
            )}
            {renderFieldData(
              "License Expiry Date",
              `${
                isValidDate(prescreen.applicant?.driverLicenseExpires) &&
                prescreen.applicant?.driverLicenseExpires
                  ? formatDate(
                      new Date(prescreen.applicant.driverLicenseExpires)
                    )
                  : "-"
              }`
            )}
          </div>
        </Grid>
        <Grid xs={6}>
          <div className={styles.container}>
            <div className={styles.titleSecondary}>Vehicle</div>
            {renderFieldData("VIN", `${prescreen.vehicle?.VIN ?? "-"}`)}
            {renderFieldData("Make", `${prescreen.vehicle?.make ?? "-"}`)}
            {renderFieldData("Model", `${prescreen.vehicle?.model ?? "-"}`)}
            {renderFieldData("Year", `${prescreen.vehicle?.year ?? "-"}`)}
          </div>
        </Grid>
        {prescreen?.prediction?.predictionFailed && (
          <Grid xs={6}>
            <div className={styles.container}>
              <div className={styles.titleSecondary}>Prediction</div>

              <div className={styles.footer}>
                <IoMdInformationCircle size={40} className={styles.icon} />
                <div>
                  This Prescreen data is insufficient at this stage to make
                  accurate predictions.
                </div>
              </div>
            </div>
          </Grid>
        )}
        {prescreen.prediction && !prescreen.prediction.predictionFailed && (
          <Grid xs={6}>
            <div className={styles.container}>
              <div className={styles.titleSecondary}>Prediction</div>
              <div className={styles.prediction}>
                This vehicle has a
                <span className={styles.bold}>
                  {` ${(
                    prescreen.prediction?.decision?.probability * 100
                  ).toFixed(2)}% `}
                </span>
                likelihood of approval based on our prediction model.
              </div>
              {renderFieldData(
                "Estimated NADA",
                `${
                  formatNumberAsCurrency(
                    prescreen.vehicle?.MSRP ??
                      prescreen.prediction.financial_info.NADA,
                    "$"
                  ) ?? "-"
                }`
              )}
              {renderFieldData(
                "Estimated Amount Financed",
                `${
                  formatNumberAsCurrency(
                    prescreen.payment?.dealTotal ??
                      prescreen.prediction.financial_info.amountFinanced,
                    "$"
                  ) ?? "-"
                }`
              )}

              <div className={styles.footer}>
                <IoMdInformationCircle size={50} className={styles.icon} />
                <div>
                  This estimate is based on previous deals and serves only as a
                  predictive guideline; it does not constitute an approval or
                  disapproval.
                </div>
              </div>
            </div>
          </Grid>
        )}

        <Grid xs={6}>
          <Button
            className={styles.button}
            onClick={handleCreateApp}
            variant="blue"
          >
            {`${
              incompleteDeal ? "Open" : "Create"
            } Credit Application based on this Prescreen`}
          </Button>
        </Grid>
      </>
    );
  };
  return (
    <Grid container xs={12} spacing={2}>
      {renderPrescreen()}
    </Grid>
  );
};
export default PrescreenView;
