import styles from "./input.module.scss";

interface TextFieldProps {
  type: "text" | "email" | "password" | "tel" | "number" | "textArea";
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
  placeholder: string;
  value?: string | number;
  containerStyle?: React.CSSProperties;
  onKeyUp?: React.KeyboardEventHandler<HTMLInputElement> | undefined;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement> | undefined;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  onFocus?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  onBlur?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  id?: string;
  name?: string;
  disabled?: boolean;
  required?: boolean;
  inputStyle?: React.CSSProperties;
  iconStyle?: React.CSSProperties;
  className?: string;
  showPlaceHolderTop?: boolean;
  autofilled?: boolean;
}

const TextField = ({
  type,
  startIcon,
  endIcon,
  placeholder,
  onChange,
  onKeyDown,
  onBlur,
  onFocus,
  inputStyle,
  iconStyle,
  value,
  containerStyle,
  id,
  onKeyUp,
  name,
  required = false,
  disabled = false,
  showPlaceHolderTop = true,
  className = "",
  autofilled = false,
}: TextFieldProps) => {
  return (
    <div
      className={`${styles.inputContainer} ${
        autofilled && styles.autofilled
      } ${className} `}
      style={
        containerStyle
          ? containerStyle?.height
            ? containerStyle
            : { ...containerStyle }
          : {}
      }
    >
      {value && showPlaceHolderTop && (
        <label className={styles.label} htmlFor={name}>
          {`${placeholder}${required ? " *" : ""}`}
          {autofilled ? (
            <span className={styles.autofilledLabel}> (Autofilled)</span>
          ) : (
            ""
          )}
        </label>
      )}
      {startIcon ? (
        <div style={iconStyle} className={`${styles.icon} ${styles.left}`}>
          {startIcon}
        </div>
      ) : (
        <></>
      )}
      {endIcon ? (
        <div style={iconStyle} className={`${styles.icon} ${styles.right}`}>
          {endIcon}
        </div>
      ) : (
        <></>
      )}
      <input
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        style={inputStyle}
        disabled={disabled}
        id={id}
        data-testid={id}
        name={name}
        required={required}
        defaultValue={value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        type={type}
        className={
          startIcon
            ? [styles.input, styles.inputWithStartIcon].join(" ")
            : styles.input
        }
        placeholder={
          !value ? `${placeholder}${required ? " *" : ""}` : undefined
        }
      />
    </div>
  );
};
export default TextField;
