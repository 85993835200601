import axios from "axios";

import { FlatDeal } from "@Types/deal";
import { auth } from "@Firebase/firebase";
import IncompleteDeal from "@Types/incomplete_application";
import { HttpQuery } from "@Types/http";

export const getIncompleteApplications =
  (query?: HttpQuery): (() => Promise<IncompleteDeal[]>) =>
  async () => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.get(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/incomplete_application/`,
      {
        headers: {
          authorization: `Bearer ${token}`,
          query:
            query && Object.keys(query)?.length
              ? JSON.stringify(query)
              : JSON.stringify({ options: { sort: { createdAt: -1 } } }),
        },
      }
    );

    return response.data.result?.docs ?? [];
  };

export const getIncompleteApplication =
  (id: string): (() => Promise<IncompleteDeal>) =>
  async () => {
    const token = await auth.currentUser?.getIdToken();
    const response = await axios.get(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/incomplete_application/${id}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data.result;
  };
export const updateIncompleteApplication =
  (): ((request: IncompleteDeal) => Promise<IncompleteDeal>) =>
  async (request) => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.put(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/incomplete_application/${request._id}`,
      request,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data.result;
  };

export const deleteIncompleteApplication =
  (): ((incompleteDeal: IncompleteDeal) => Promise<IncompleteDeal>) =>
  async (incompleteDeal) => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.delete(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/incomplete_application/${incompleteDeal._id}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data.result;
  };
export const createIncompleteApplication =
  (): ((flatDeal: FlatDeal) => Promise<IncompleteDeal>) => async (flatDeal) => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.post(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/incomplete_application/`,
      { data: flatDeal },
      {
        headers: {
          authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    return response.data.result;
  };
